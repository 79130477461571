var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',{staticClass:"row"},[(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_INVOICES)
        )?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List ResellerInvoices' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-single-copy-04"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.RESELLER_INVOICES")))])])]}}],null,false,2609888005)}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_PAYMENTS)
        )?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List ResellerPayments' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-money-coins"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.RESELLER_PAYMENTS")))])])]}}],null,false,983953712)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SUBSCRIPTIONS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Subscriptions' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"fa fa-2x fa-id-card"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.SUBSCRIPTIONS")))])])]}}],null,false,3119424113)}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_PRODUCTS)
        )?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List ResellerProducts' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"fa fa-2x fa-cubes"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.RESELLER_PRODUCTS")))])])]}}],null,false,366691622)}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_SERVICES)
        )?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List ResellerServices' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"fa fa-2x fa-cube"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.RESELLER_SERVICES")))])])]}}],null,false,2150960739)}):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PACKAGES))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List Packages' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-box-2"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.PACKAGES")))])])]}}],null,false,606878166)}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }